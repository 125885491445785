import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import dayjs from 'dayjs';
import createPersistedState from 'vuex-persistedstate';
import { MessageBox, Notification as ElNotification } from 'element-ui';
import { COMPANY_ROLE_CD, COMPANY_ROLE_CD_VARIABLES, COMPANY_ROLE_CD_VARIABLES_OPTIONS, PRIORITY_FLG, SYSTEM_PROPERTY, UNREAD_FLG, USER_ROLE_CD, LANGUAGE_SETTING, LANGUAGE_SETTING_VARIABLES, LOCAL_LANGUAGE, INITIAL_VIEW_FLG, CALENDAR_USE_CODE, INFORMATIONS_API_USE_CODE, INFORMATIONS_PUBLISHED_FLG, SCAC_VARIABLES, E_CO_USER_FLG } from 'lib-tw-common';
import router from '@/router'
import notificationLogo from "@/assets/images/logo_notification.png";
import dashboardItems from '@/dictionaries/dashboard.js';
import companyRoles from '@/dictionaries/companyRoles.json';
import { formatUtcDate } from '@/utils/searchUtil.js';
import { $api } from './ApiClient';

const TRADING_LIST_TABS = [
  'ListTrading',
  'ListTradingFlow',
];

Vue.use(Vuex);

const initialState = {
  progressBar: {
    processing: false,
    progress: 0,
    total: 0
  },
  drawerShow: false,
  asideShow: false,
  graphicalShow: false,
  newsList: null,
  mailList: null,
  systemProperties: {},
  roleInfo: {},
  userId: null,
  userProfile: {},
  sectionNotificationSettings: [],
  companyRoleCd: null,
  userRoleCd: null,
  companyId: null,
  companyRoles: [],
  accessibleViews: {}, // アクセス可能画面一覧
  sectionHistory: {},
  dashBoardOrder: {}, // ダッシュボードの並び順
  dashBoardVisible: {}, // ダッシュボードの表示フラグ
  ownerSectionLists: [], // オーナーセクションリスト
  queries: {}, // 検索条件,
  activeFilters: {},
  unreadCount: {},
  pushCount: 0, // プッシュ通知の表示回数
  unreadOnlyNotice: false, // 通知の既読のみフラグ,
  importantMessages: [], // 重要なお知らせ
  dashBoardSummary: {
    processesCountNotAccepted: 0,
    processesCountApprovalRequest: 0,
    processesCountConfirmationTo: 0,
    processesCountNotification: 0,
    processesCountProcessAlert: 0,
  }, // ダッシュボードの件数集計
  tradingListMode: TRADING_LIST_TABS[0], // 取引一覧の表示モード 0:リスト, 1:フロー, 2:カレンダー
  notificationPermission: false,
  notifications: [], // 通知一覧,
  unreadNotifications: [], // ブラウザ通知用未読一覧
  locale: LANGUAGE_SETTING_VARIABLES[0].label, // 初期値は英語
  language: LANGUAGE_SETTING.ENGLISH, // 言語設定 初期値は英語
  localLanguage: LOCAL_LANGUAGE.JAPANESE, // ローカル言語 初期値は日本語
  tableSettings:{},
  coDocumentSigners: [], // CO Document Signerの候補
  coApplicants: [], // CO Document Signer関連のCo Applicant候補（ログインユーザーに紐づいたeCOIDのみが候補となる）
  scrollbarWidth: 0, // スクロールバーの幅
  headerHeight: 0,
  downloadStatus: {'TRADING_LIST': []}, // TSVダウンロードの状態
  userInformationV2: null
};

export default new Vuex.Store({
  state: initialState,
  mutations: {
    ['START_PROCESS']({ progressBar }) {
      progressBar.processing = true;
      progressBar.progress = 0;
      progressBar.total = 0;
    },
    ['UPDATE_PROCESS']({ progressBar }, progress) {
      progressBar.progress = progress.progress;
      progressBar.total = progress.total;
    },
    ['END_PROCESS']({ progressBar }) {
      progressBar.progress = 100;
      setTimeout(() => {
        progressBar.processing = false;
        progressBar.progress = 0;
        progressBar.total = 0;
      }, 300);
    },
    ['SET_DRAWER'](state, payload) {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      state.drawerShow = payload;
    },
    ['SET_ASIDE'](state, payload) {
      state.asideShow = payload;
    },
    ['SET_GRAPHICAL'](state, payload) {
      state.graphicalShow = payload;
    },
    ['SET_NEWS_LIST'](state, payload) {
      state.newsList = payload;
    },
    ['SET_MAIL_LIST'](state, payload) {
      state.mailList = payload;
    },
    ['SET_SYSTEM_PROPERTIES'](state, payload) {
      state.systemProperties = payload;
    },
    ['SET_ROLE_INFO'](state, payload) {
      state.roleInfo = payload;
      state.companyId = payload.companyId;
      state.companyRoles = payload.companyRoles;
      state.userRoleCd = payload.baseUserRoleCd || _.get(payload, 'all[0].userRoleCd');
    },
    ['SET_COMPANY_ROLE'](state, payload) {
      state.companyRoleCd = payload;
    },
    ['SET_USER_ID'](state, payload) {
      state.userId = payload;
    },
    ['SET_USER_PROFILE'](state, payload) {
      state.userProfile = payload;
      state.localLanguage = state.userProfile.localLanguage || LOCAL_LANGUAGE.JAPANESE; // マスタで設定されていない場合は日本語
      state.userId = state.userProfile.userId;
    },
    ['SET_SECTION_NOTIFICATION_SETTINGS'](state, payload) {
      state.sectionNotificationSettings = payload;
    },
    // 選択したセクションを保存
    ['SET_SECTION_HISTORY'](state, payload) {
      const history = _.get(state, `sectionHistory.${state.userId}.${payload.processId}`);
      if (_.isArray(history)) {
        history.unshift(payload.section);
        _.set(state, `sectionHistory.${state.userId}.${payload.processId}`, _.uniqBy(history, 'value').slice(0, 5));
        state.sectionHistory = Object.assign({}, state.sectionHistory);
      } else {
        _.set(state, `sectionHistory.${state.userId}.${payload.processId}`, [payload.section]);
        state.sectionHistory = Object.assign({}, state.sectionHistory);
      }
    },
    // ダッシュボードの並び順を保存
    ['SET_DASHBOARD_ORDER'](state, payload) {
      _.set(state, `dashBoardOrder.${state.userId}`, payload);
      state.dashBoardOrder = Object.assign({}, state.dashBoardOrder);
    },
    // ダッシュボードの表示フラグを保存
    ['SET_DASHBOARD_VISIBLE'](state, payload) {
      _.set(state, `dashBoardVisible.${state.userId}`, payload);
      state.dashBoardVisible = Object.assign({}, state.dashBoardVisible);
    },
    // オーナーセクションリストを保存
    ['SET_OWNER_SECTION_LISTS'](state, payload) {
      _.set(state.ownerSectionLists, state.userId, payload);
      state.ownerSectionLists = Object.assign({}, state.ownerSectionLists);
    },
    // 検索条件を保存
    ['SET_QUERIES'](state, payload) {
      _.set(state, `queries.${state.userId}.${payload.key}`, _.cloneDeep(payload.queries));
      state.queries = Object.assign({}, state.queries);
    },
    // デフォルトフィルター及びユーザーがアクティブにしたフィルターを保存
    ['SET_ACTIVE_FILTERS'] (state, payload) {
      _.set(state, `filters.${state.userId}.${payload.key}.active`, payload.filters);
    },
    // 通知の既読のみフラグをセット
    ['SET_UNREAD_ONLY_NOTICE'](state, payload) {
      _.set(state.unreadOnlyNotice, state.userId, payload);
      state.unreadOnlyNotice = Object.assign({}, state.unreadOnlyNotice);
    },
    // 通知の未読数をセット
    ['SET_UNREAD_COUNT'](state, payload) {
      _.set(state.unreadCount, state.userId, payload);
      state.unreadCount = Object.assign({}, state.unreadCount);
    },
    // プッシュ通知の表示回数をセットします（TODO: 最大値を5に設定中、定数化する）
    ['SET_PUSH_COUNT'](state, payload) {
      state.pushCount = payload <= 5 ? payload : 5;
    },
    // 重要なお知らせをセット
    ['SET_IMPORTANT_MESSAGE'](state, payload) {
      state.importantMessages = payload;
    },
    // ダッシュボードの集計件数をセット
    ['SET_DASHBOARD_SUMMARY'](state, payload) {
      state.dashBoardSummary = payload;
    },
    // 取引一覧の表示モードをセット
    ['SET_TRADING_LIST_MODE'](state, payload) {
      state.tradingListMode = TRADING_LIST_TABS[payload];
    },
    // 通知の許可状態をセット
    ['SET_NOTIFICATION_PERMISSION'](state, payload) {
      state.notificationPermission = payload;
    },
    // 通知一覧をセット
    ['SET_NOTIFICATION_LIST'](state, payload) {
      state.notifications = payload;
    },
    // 通知一覧に追加分をセット
    ['CONCAT_NOTIFICATION_LIST'](state, payload) {
      state.notifications = state.notifications.concat(payload);
    },
    // ブラウザ通知用未読一覧をセット
    ['SET_UNREAD_NOTIFICATION_LIST'](state, payload) {
      state.unreadNotifications = payload;
    },
    // 通知を既読にします
    ['SET_NOTIFICATION_READ'](state, payload) {
      const index = _.findIndex(state.notifications, { browserNoticeId: payload });
      _.set(state.notifications, `[${index}].read`, 1);
      state.notifications = [...state.notifications];
    },
    // 閲覧可能画面をセットします
    ['SET_ACCESSIBLE_VIEWS'](state, payload) {
      _.set(state.accessibleViews, state.userId, payload);
      state.accessibleViews = Object.assign({}, state.accessibleViews);
    },
    // 言語をセットします
    ['SET_LOCALE'](state, { language, locale }) {
      state.language = language;
      state.locale = locale;
    },
    // テーブルの表示、非表示設定をセットします
    ['SET_TABLE_SETTING'] (state, {tableId, tableSetting}) {
      _.set(state, `tableSettings.${tableId}`, {
        ..._.get(state, `tableSettings.${tableId}`, {}),
        ...tableSetting,
      });
    },
    //
    ['SET_CO_DOCUMENT_SIGNERS'](state, payload) {
      state.coDocumentSigners = payload;
    },
    ['SET_CO_APPLICANTS'](state, payload) {
      state.coApplicants = payload;
    },
    // ブラウザ標準のスクロールバーの幅を返却します
    ['SET_SCROLLBAR_WIDTH'](state, payload) {
      state.scrollbarWidth = payload;
    },
    ['SET_HEADER_HEIGHT'](state, payload) {
      state.headerHeight = payload;
    },
    // TSVダウンロードの状態をセットします
    ['SET_DOWNLOAD_STATUS'](state, payload) {
      _.set(state, `downloadStatus.${payload.key}`, _.cloneDeep(payload.downloadStatus));
      state.downloadStatus = Object.assign({}, state.downloadStatus);
    },
    ['SET_USER_INFORMATION_V2'](state, payload) {
      state.userInformationV2 = payload
    }
  },
  actions: {
    // 認証エラー時にログイン画面にリダイレクトします
    async ['REDIRECT_TO_LOGIN']() {
      $api.authErrorHandler();
    },
    // completed を表示します
    // eslint-disable-next-line no-unused-vars
    ['SHOW_COMPLETED']({ commit }, message) {
      ElNotification({
        title: message || 'Completed',
        customClass: 'completed',
        showClose: false,
        offset: 112,
        duration: 1000
      });
    },
    // alert を表示します
    // eslint-disable-next-line no-unused-vars
    ['SHOW_ALERT']({ commit }, message) {
      const text = message ? message.replace(/\n/g, '<br />') : '';
      return new Promise((resolve, reject) => {
        MessageBox.alert(text, '', {
          dangerouslyUseHTMLString: true,
          roundButton: true,
          customClass: 'confirm'
        })
          .then(() => {
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    // confirm を表示します
    // eslint-disable-next-line no-unused-vars
    ['SHOW_CONFIRM']({ commit }, message) {
      const text = message ? message.replace(/\n/g, '<br />') : '';
      return new Promise((resolve, reject) => {
        MessageBox.confirm(text, '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true,
          roundButton: true,
          customClass: 'confirm'
        })
          .then(() => {
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    // システムエンティティを取得します
    ['GET_SYSTEM_PROPERTIES']({ commit }) {
      const params = {
        lslConfig: {
          serviceCode: 'lakeel-property-api',
          apiCode: 'post-postMasterDatas'
        },
        data: {
          count: 0,
          masterDataQuery: _.map(SYSTEM_PROPERTY, s => {
            return { masterCode: s, sort: 'id' };
          })
        }
      };

      $api
        .request(params)
        .then(res => {
          const payload = _.reduce(
            res,
            (r, o) => {
              if (o.masterCode === 'SYS_MST_PORT_AND_PLACE') {
                r[o.masterCode] = _.map(_.get(o, 'records.masterDatas') || [], place => {
                  return {
                    id: place.code,
                    code: place.code,
                    name: place.name,
                    lowerName: place.name.toLowerCase()
                  };
                });
              } else {
                r[o.masterCode] = _.get(o, 'records.masterDatas') || [];
              }
              return r;
            },
            {}
          );
          commit('SET_SYSTEM_PROPERTIES', payload);
        })
        .catch(err => {
          MessageBox(err.message, '', {
            roundButton: true
          });
        });
    },
    // ユーザー情報を取得します
    ['GET_USER_INFORMATION']({ commit, state, getters }, query) {
      return new Promise((resolve, reject) => {
        // bff_auth_4 ロール取得（トラン）
        const params = {
          lslConfig: {
            serviceCode: 'tw-user-entity-permission-bff-api',
            apiCode: 'get_/v1/permission/roles/transaction/section',
            query: query
          }
        };

        $api
          .request(params)
          .then(res => {
            commit('SET_ROLE_INFO', res);
            if (getters.hasBothShipper) {
              commit('SET_COMPANY_ROLE', 1);
            } else {
              commit('SET_COMPANY_ROLE', state.companyRoles[0]);
            }
            // dispatch('GET_ACCESSIBLE_VIEWS');
            resolve();
          })
          .catch(() => {
            MessageBox('Not available to this user', '', {
              roundButton: true
            })
              .then(() => {
                reject();
              })
              .catch(() => {
                reject();
              });
          });
      });
    },
    // ユーザーロールの兼務者が発生しうる部分はbff_auth_5 ロール取得（ユーザロール）からのレスポンスの値で制御し、
    // それ以外の箇所はbff_auth_4 ロール取得（トラン）のレスポンスを見たまま
    ['GET_USER_INFORMATION_V2']({ commit }) {
      return new Promise((resolve, reject) => {
        // bff_auth_5 ロール取得（ユーザロール）
        // ユーザーロールとカンパニーロールを取得したい際に利用します。保持している全てのユーザロールを各フラグで返却します。
        const params = {
          lslConfig: {
            serviceCode: 'tw-user-entity-permission-bff-api',
            apiCode: 'get_/v1/permission/roles/transaction/userRole',
          }
        };

        $api
          .request(params)
          .then(res => {
            commit('SET_USER_INFORMATION_V2', res)
            resolve();
          })
          .catch(() => {
            MessageBox('Not available to this user', '', {
              roundButton: true
            })
              .then(() => {
                reject();
              })
              .catch(() => {
                reject();
              });
          });
      });
    },
    // アクセス可能画面一覧を取得します
    ['GET_ACCESSIBLE_VIEWS']({ state, commit }) {
      // bff_auth_1 アクセス可能画面一覧取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-permission-bff-api',
          apiCode: 'get_/v1/permission/pages',
          query: {
            companyRoleCd: state.companyRoleCd,
            pageFlg: 1
          }
        }
      };

      // console.log(router.currentRoute.path);

      $api
        .request(params)
        .then(res => {
          commit('SET_ACCESSIBLE_VIEWS', res.pageUrls);
          // if (_.some(res.pageUrls, url => {return _.startsWith(url, route.path);})) {
          //   console.log('404');
          // }
        })
        .catch(() => {});
    },
    // ユーザープロフィールを取得します
    ['GET_USER_PROFILE']({ state, commit }) {
      return new Promise((resolve, reject) => {
        // bff_pf_1 ユーザープロフィール情報取得BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/profile/{companyId}',
            path: {
              companyId: state.companyId
            }
          }
        };

        $api
          .request(params)
          .then(res => {
            commit('SET_USER_PROFILE', res.users[0]);
            resolve(state.userProfile.language);
          })
          .catch(err => {
            MessageBox(err.message, '', {
              roundButton: true
            });
            reject();
          });
      });
    },
    // ユーザー通知設定（セクション）を取得します
    ['GET_USER_NOTIFICATION_SETTINGS']({ commit }) {
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/user-settings/notification-settings'
        }
      }
      return new Promise((resolve, reject) => {
        $api
          .request(params)
          .then(res => {
            commit('SET_SECTION_NOTIFICATION_SETTINGS', res.sectionNotificationSettingList);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    // セクションを取得します
    ['GET_SECTIONS']({ state }, query) {
      return new Promise((resolve, reject) => {
        const params = {
          // bff_trading_pattern_10 取引先セクション取得BFF
          lslConfig: {
            serviceCode: 'tw-user-entity-bff-api',
            apiCode: 'get_/v1/{companyId}/trading-patterns/customer-section',
            path: {
              companyId: state.companyId
            },
            query: query
          }
        };

        $api
          .request(params)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    // オーナーセクションを取得します
    ['GET_OWNER_SECTIONS']({ commit }) {
      const params = {
        // bff_db_2 ダッシュボードカレンダー取得BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/calendar',
          query: {
            displayDateStart: dayjs().format('YYYY-MM-DD'),
            displayDateEnd: dayjs().format('YYYY-MM-DD'),
            calendarUseCode: CALENDAR_USE_CODE.DASHBOARD,
            initialFlag: INITIAL_VIEW_FLG.ON
          }
        }
      };

      $api
        .request(params)
        .then(res => {
          if (!_.isEmpty(res.ownerSectionLists)) {
            commit(
              'SET_OWNER_SECTION_LISTS',
              _.map(res.ownerSectionLists, s => {
                return {
                  ...s,
                  label: s.sectionShortName,
                  value: s.sectionId
                };
              })
            );
          }
        })
        .catch(() => {});
    },
    // お知らせ一覧を取得します
    ['GET_NEWS_LIST']({ commit }) {
      // bff_in_1 お知らせ一覧取得BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/informations',
          query: {
            conditions: {
              informationTitle: null,
              informationDateFrom: null,
              informationDateTo: null,
              publishedFlg: INFORMATIONS_PUBLISHED_FLG.PUBLISHED,
              newsType: null,
              priorityFlg: null,
              updateUserId: null,
              updateDate: null
            },
            apiUseCode: INFORMATIONS_API_USE_CODE.LIST,
            limit: 10,
            offset: 0
          }
        }
      };

      $api
        .request(params)
        .then(res => {
          commit('SET_NEWS_LIST', res.informationList);
        })
        .catch(err => {
          commit('SET_NEWS_LIST', []);
          MessageBox(err.message, '', {
            roundButton: true
          });
        });
    },
    // 重要なお知らせを取得します
    ['GET_IMPORTANT_MASSAGES']({ commit }) {
      // bff_in_1 お知らせ一覧取得BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/informations',
          query: {
            conditions: {
              informationTitle: null,
              informationDateFrom: null,
              informationDateTo: null,
              publishedFlg: INFORMATIONS_PUBLISHED_FLG.PUBLISHED,
              newsType: null,
              priorityFlg: PRIORITY_FLG.ON,
              updateUserId: null,
              updateDate: null
            },
            apiUseCode: INFORMATIONS_API_USE_CODE.LIST,
            limit: 3,
            offset: 0
          }
        }
      };

      $api
        .request(params)
        .then(res => {
          commit('SET_IMPORTANT_MESSAGE', res.informationList);
        })
        .catch(err => {
          MessageBox(err.message, '', {
            roundButton: true
          });
        });
    },
    // メール送信履歴を取得します
    ['GET_MAIL_LIST']({ commit }, query) {
      // bff_ms_1 メール送信履歴一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/email-transmission-histories/search',
          query: query
        }
      };

      $api
        .request(params)
        .then(res => {
          commit('SET_MAIL_LIST', res.emailTransmissionHistories);
        })
        .catch(err => {
          commit('SET_MAIL_LIST', []);
          MessageBox(err.message, '', {
            roundButton: true
          });
        });
    },
    // 通知一覧を取得します
    ['GET_NOTIFICATION_LIST']({ commit, dispatch, getters }, { unReadFlg, sendNotification, referenceDate }) {
      // bff_ntf_2 ブラウザ通知情報取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-notification-bff-api',
          apiCode: 'get_/v1/browser-notice',
          query: {
            unReadFlg: unReadFlg
          }
        }
      };

      if (referenceDate) params.lslConfig.query.referenceDate = referenceDate;
      if (sendNotification) params.lslConfig.query.count = getters.getPushCount;

      $api
        .request(params)
        .then(res => {
          if (sendNotification) {
            commit('SET_UNREAD_NOTIFICATION_LIST', res.noticeInfo);
            dispatch('SEND_NOTIFICATION'); // WEB通知を送信
          } else if (referenceDate) {
            commit('CONCAT_NOTIFICATION_LIST', res.noticeInfo);
          } else {
            commit('SET_NOTIFICATION_LIST', res.noticeInfo);
          }
        })
        .catch(() => {
          // MessageBox(err.message, '', {
          //   roundButton: true,
          // });
        });
    },
    // 通知の既読を更新します
    ['READ_NOTIFICATION']({ commit }, browserNoticeId) {
      commit('SET_NOTIFICATION_READ', browserNoticeId);
      // bff_ntf_3 ブラウザ通知ステータス更新
      const params = {
        lslConfig: {
          serviceCode: 'tw-notification-bff-api',
          apiCode: 'post_/v1/browser-notice/{browserNoticeId}',
          path: {
            browserNoticeId: browserNoticeId
          }
        }
      };

      $api
        .request(params)
        .then(() => {})
        .catch(() => {});
    },
    // 通知の未読数を取得します
    ['GET_UNREAD_COUNT']({ commit, dispatch, getters }) {
      // eslint-disable-next-line no-unused-vars
      const interval = 5 * 60 * 1000; // 5分
      // bff_ntf_1 ブラウザ未読通知情報取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-notification-bff-api',
          apiCode: 'get_/v1/browser-notice/unread-count'
        }
      };

      $api
        .request(params)
        .then(res => {
          if (getters.getUnreadCount < res.count) {
            commit('SET_PUSH_COUNT', res.count - getters.getUnreadCount);
          }
          commit('SET_UNREAD_COUNT', res.count || 0);
          if (getters.getPushCount) dispatch('GET_NOTIFICATION_LIST', { unReadFlg: UNREAD_FLG.UNREAD, sendNotification: true }); // 通知表示の差分があれば、お知らせ一覧を再取得
          // setTimeout(() => {
          //   dispatch('GET_UNREAD_COUNT');
          // }, interval);
        })
        .catch(() => {
          // setTimeout(() => {
          //   dispatch('GET_UNREAD_COUNT');
          // }, interval);
        });
    },
    // WEB通知を送信します
    async ['SEND_NOTIFICATION']({ commit, dispatch, state, getters }) {
      let pushCount = getters.getPushCount; // WEB通知を表示する通知数
      if ('Notification' in window && pushCount) {
        // ブラウザ通知の許可を取ります
        await window.Notification.requestPermission();
        console.log('Notification.permission: ', window.Notification.permission);

        if (window.Notification.permission === 'granted') {
          let pushNotifications = [...state.unreadNotifications].reverse(); // 未読通知一覧から、古いものから表示
          for (let key in pushNotifications) {
            // WEB通知する一覧をループ処理
            const item = pushNotifications[key]; // 通知1件分
            const title = 'TradeWaltz'; //item.informationTitle; // タイトル
            const option = {
              body: item.userNameFrom + item.browserNoticeMessage, // 本文
              icon: notificationLogo, // アイコン
              // tag: item.priorityFlg, // タグ（優先順位ごとに分ける
              data: item.browserNoticeId // ID（クリックイベント遷移で、routerに渡す）
            };

            // WEB通知を実行
            const notification = new window.Notification(title, option);
            // WEB通知をクリックした際の処理、プロセス詳細へ遷移する
            notification.onclick = () => {
              router
                .push({
                  name: `Process${_.capitalize(item.processId.replace(/\d/g, ''))}`,
                  params: { entityId: item.entityId, processSeq: item.processSeq, _processId: item.processId.toLowerCase() }
                })
                .catch(error => {
                  if (error.name !== 'NavigationDuplicated') throw error;
                });
              if (!item.read) {
                dispatch('READ_NOTIFICATION', item.browserNoticeId);
                commit('SET_UNREAD_COUNT', Math.max(getters.getUnreadCount - 1, 0));
              }
              notification.close();
            };
            console.log(notification);

            // 置き換え前に、1秒待って次のループ処理を行う
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
      } else {
        console.log('unreadCountDiff:' + pushCount);
      }
      commit('SET_PUSH_COUNT', 0); // 処理完了後、WEB通知を表示する通知数をリセット
    },
    // COのDocument Signerの候補を取得します
    ['GET_CO_DOCUMENT_SIGNERS']({ state, commit }, role) {
      const currentDate = formatUtcDate(new Date());
      // bff_user_2 ユーザー検索
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/{companyId}/users/search',
          path: {
            companyId: state.companyId
          },
          query: {
            organizationId: role.organizationId,
            eCoUserFlg: E_CO_USER_FLG.ON,
            validityStartDate: currentDate,
            validityEndDate: currentDate,
          }
        }
      };

      $api
        .request(params)
        .then(res => {
          commit(
            'SET_CO_DOCUMENT_SIGNERS',
            _.map(res.users, user => {
              return {
                label: user.eCoIdentificationName,
                code: user.eCoUserId
              };
            })
          );
          // Co Applicant候補内容の取得はuserIdも指定する
          params.lslConfig.query.userIds = [ state.userId ];
          $api
            .request(params)
            .then(res => {
              commit(
                'SET_CO_APPLICANTS',
                _.map(res.users, user => {
                  return {
                    label: user.eCoIdentificationName,
                    code: user.eCoUserId
                  };
                })
              )
            })
            .catch(err => {
              throw err
            });
        })
        .catch(err => {
          MessageBox(err.message, '', {
            roundButton: true
          });
        });
    },
    // プログレスバーを更新します
    async ['UPDATE_PROGRESS']({ state, dispatch, commit }, progress) {
      // サーバへのデータ送信完了で90％ サーバからレスポンスが返ってくる間90％～99%まで少しずつ増加し、100％で完了
      if (!state.progressBar.processing || state.progressBar.progress >= 99.75) {
        // サーバからのレスポンス待ち状態でも100%にはしない
        return;
      } else if (state.progressBar.progress >= 90) {
        // 90%以上 残%はサーバからのレスポンスを待ちつつ時間経過で増やす
        await commit('UPDATE_PROCESS', {
          progress: state.progressBar.progress + 0.25,
          total: state.progressBar.total,
        });
        setTimeout(() => {
          dispatch('UPDATE_PROGRESS', {
            progress: state.progressBar.progress,
            total: state.progressBar.total,
          });
        }, 250);
      } else {
        // 90%以下 axiosのprogressEventから受け取った値を%換算
        const isFast = progress.loaded === progress.total; // リクエストサイズが少なく一度で100%に到達する場合
        const time = isFast ? progress.loaded / progress.total * 90 : progress.loaded / progress.total * 91;
        await commit('UPDATE_PROCESS', {
          progress: time,
          total: progress.total,
        });
        if (isFast) {
          dispatch('UPDATE_PROGRESS', {
            progress: state.progressBar.progress,
            total: state.progressBar.total,
          });
        }
      }
    },
    // TSVダウンロードの状態を更新します
    ['SET_DOWNLOAD_STATUS']({ state, commit }, {key, downloadStatus}) {
      const status = _.get(state, `downloadStatus.${key}`) || [];
      if (!downloadStatus.message) {
        // メッセージが空の場合は、ダウンロード状態を削除
        commit('SET_DOWNLOAD_STATUS', { key, downloadStatus: _.reject(status, {id: downloadStatus.id}) });
        return;
      }
      const index = _.findIndex(status, {id: downloadStatus.id});
      if (index === -1) {
        // ダウンロード状態が未登録の場合は追加
        status.push(downloadStatus)
        commit('SET_DOWNLOAD_STATUS', { key, downloadStatus: status });
        return;
      }
      // ダウンロード状態が登録済みの場合は更新
      status[index] = downloadStatus;
      commit('SET_DOWNLOAD_STATUS', { key, downloadStatus: status });
    },
  },
  getters: {
    getSectionHistory: state => processId => {
      return _.compact(_.get(state, `sectionHistory.${state.userId}.${processId}`) || []);
    },
    getDashBoardOrder: state => {
      const order = _.get(state, `dashBoardOrder.${state.userId}`);
      return order;
    },
    getDashBoardVisible: state => {
      const visible = _.get(state, `dashBoardVisible.${state.userId}`) || {};
      if (_.isEmpty(visible)) {
        return _.reduce(
          dashboardItems,
          (ret, item) => {
            ret[item.name] = true;
            return ret;
          },
          {}
        );
      }
      return visible;
    },
    getQuantityUnit: (state, getters) => unitId => {
      const unit = _.find(getters.getSysMstUnit, { code: unitId });
      if (unit) {
        return unit.value;
      }
      return '';
    },
    getCurrency: (state, getters) => currencyId => {
      return _.find(getters.getSysMstCurrency, { alphabeticCode: currencyId });
    },
    // オーナーセクションリストを返却します
    getOwnerSectionLists: state => {
      return _.get(state, `ownerSectionLists.${state.userId}`) || [];
    },
    // 検索条件を返却します
    getQueries: state => key => {
      return _.get(state, `queries.${state.userId}.${key}`);
    },
    // デフォルトフィルター及びユーザーがアクティブにしたフィルターを返却
    getActiveFilters: state => key => {
      return _.get(state, `filters.${state.userId}.${key}.active`);
    },
    // 通知の既読のみフラグを返却します
    getUnreadOnlyNotice: state => {
      return _.get(state, `unreadOnlyNotice.${state.userId}`) || false;
    },
    // 通知の未読数を返却します
    getUnreadCount: state => {
      return _.get(state, `unreadCount.${state.userId}`) || 0;
    },
    // 荷主かどうかを返却します
    isShipper: state => {
      return _.some(state.companyRoles, cd => {
        return [COMPANY_ROLE_CD.EXPORTER, COMPANY_ROLE_CD.IMPORTER, COMPANY_ROLE_CD.INTERMEDIATE].includes(cd);
      });
    },
    // 輸出者と輸入者のどちらのロールも持っているかを返却します
    hasBothShipper: state => {
      return state.companyRoles.includes(COMPANY_ROLE_CD.EXPORTER) && state.companyRoles.includes(COMPANY_ROLE_CD.IMPORTER);
    },
    // FWDロールを持っているかを返却します
    isFwd: state => {
      return state.companyRoles.includes(COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER);
    },
    // 三国間ロールを持っているかを返却します
    isIntermediate: state => {
      return state.companyRoles.includes(COMPANY_ROLE_CD.INTERMEDIATE);
    },
    // 参照者かどうかを返却します
    isViewer: state => {
      return state.userRoleCd === USER_ROLE_CD.VIEWER;
    },
    // 担当者かどうかを返却します
    isPic: state => {
      return state.userRoleCd === USER_ROLE_CD.PIC;
    },
    // 承認者かどうかを返却します
    isApprover: state => {
      return state.userRoleCd === USER_ROLE_CD.APPROVER;
    },
    // 管理者かどうかを返却します
    isAdmin: state => {
      return state.userRoleCd === USER_ROLE_CD.COMPANY_ADMIN || state.userRoleCd === USER_ROLE_CD.TW_ADMIN;
    },
    // TW管理者かどうかを返却します
    isTwAdmin: state => {
      return state.userRoleCd === USER_ROLE_CD.TW_ADMIN;
    },
    // WEB通知の表示回数を返却します
    getPushCount: state => {
      return state.pushCount;
    },
    // 通知の既読のみフラグを返却します
    getAccessibleViews: state => {
      return _.get(state, `accessibleViews.${state.userId}`);
    },
    // マスタ項目の言語キーを返却します
    getLanguageKey: state => {
      return state.language === LANGUAGE_SETTING.ENGLISH ? 'en' : 'local';
    },
    // ページの閲覧可否を返却します
    canAccess: state => pageName => {
      return _.some(state.companyRoles, cd => {
        const companyRole = _.findKey(COMPANY_ROLE_CD_VARIABLES, value => value === cd);
        return _.get(_.find(companyRoles, { page: pageName }), companyRole);
      });
    },
    getTableSetting: state => tableId => {
      return _.get(state, `tableSettings.${tableId}`)
    },
    getSysMstPortAndPlace: state => {
      return _.get(state.systemProperties, 'SYS_MST_PORT_AND_PLACE') || [];
    },
    getSysMstCountry: state => {
      return _.get(state.systemProperties, 'SYS_MST_COUNTRY') || [];
    },
    getSysMstUnit: state => {
      return _.get(state.systemProperties, 'SYS_MST_UNIT') || [];
    },
    getSysMstCurrency: state => {
      return _.get(state.systemProperties, 'SYS_MST_CURRENCY') || [];
    },
    getSysMstPackageType: state => {
      return _.get(state.systemProperties, 'SYS_MST_PACKAGE_TYPE') || [];
    },
    getSysMstContainerType: state => {
      return _.get(state.systemProperties, 'SYS_MST_CONTAINER_TYPE') || [];
    },
    getCompanyRoleCdVariablesOptions: () => {
      return COMPANY_ROLE_CD_VARIABLES_OPTIONS;
    },
    getScacVariables: () => {
      // 本番環境ではテスト用船社を除外
      if (location.host.includes('www.v2.tradewaltz.com')) {
        return _.reject(SCAC_VARIABLES, o => ['INTT', 'PABV'].includes(o.code));
      }
      return SCAC_VARIABLES;
    },
    // ページのTSVダウンロードの状態を返却します
    getDownloadStatus: state => key => {
      const status = _.get(state, `downloadStatus.${key}`);
      if (_.isEmpty(status)) {
        return ''
      }

      if (status.length > 1) {
        return `${status.length} downloads in progress...`;
      }
      // 0%の時は件数表示にする（sは付けない）
      if (status[0].message === '0%') {
        return `${status.length} download in progress...`;
      }
      return `${status[0].message} downloads in progress...`;
    },
    getSystemMasterData: state => {
      return (key, code = null) => {
        const targetState = state.systemProperties?.[key];
        if(code === null) return targetState;
        return targetState?.find(item => item.code === code);
      }
    }
  },
  plugins: [
    // createPersistedState({
    //   key: 'TradeWaltzSession',
    //   storage: window.sessionStorage,
    //   paths: ['userProfile', 'companyId']
    // }),
    createPersistedState({
      key: 'TradeWaltz',
      storage: window.localStorage,
      paths: ['sectionHistory', 'dashBoardOrder', 'dashBoardVisible', 'ownerSectionLists', 'queries', 'filters', 'unreadOnlyNotice', 'unreadCount', 'locale', 'language', 'localLanguage', 'tableSettings']
    })
  ]
});
