// エンティティ
export const entityNames = {
  CONTRACT: {
    nameJp: '契約',
    nameEn: 'Contract',
    iconName: 'contract',
    entityId: 'CONTRACT',
    pageUrl: '/entity/contract/',
    pageName: 'ListSalesContract',
    approval: true,
    code: 1
  },
  LC: {
    nameJp: 'L/C',
    nameEn: 'L/C',
    iconName: 'lc',
    entityId: 'LC',
    pageUrl: '/entity/lc/',
    pageName: 'ListLC',
    approval: true,
    code: 2
  },
  TRANSPORTATION_AG: {
    nameJp: '輸送契約',
    nameEn: 'Transportation Agreement',
    iconName: 'transportation_agreement',
    entityId: 'TRANSPORTATION_AG',
    pageUrl: '/entity/transport/',
    pageName: 'ListTransport',
    approval: true,
    code: 8
  },
  EX_CUSTOMS: {
    nameJp: '輸出通関',
    nameEn: 'Export Customs Clearance',
    iconName: 'ex_customs',
    entityId: 'EX_CUSTOMS',
    pageUrl: '/entity/ex-customs-clearance/',
    pageName: 'ListExCustomsClearance',
    approval: true,
    code: 3
  },
  TRANSPORTATION: {
    nameJp: '輸送',
    nameEn: 'Transport',
    iconName: 'transportation',
    entityId: 'TRANSPORTATION',
    pageUrl: '/entity/transport/',
    pageName: 'ListTransport',
    approval: true,
    code: 5
  },
  MARINE_IP: {
    nameJp: '海上保険',
    nameEn: 'Marine Insurance',
    iconName: 'marine_ip',
    entityId: 'MARINE_IP',
    pageUrl: '/entity/marine-insurance/',
    pageName: 'ListMarineInsurance',
    approval: true,
    code: 4
  },
  FINAL_DOCS: {
    nameJp: '決裁書類',
    nameEn: 'Final Documents',
    iconName: 'final_docs',
    entityId: 'FINAL_DOCS',
    pageUrl: '/entity/final-Documents/',
    pageName: 'ListFinalDocs',
    approval: true,
    code: 6
  },
  IM_CUSTOMS: {
    nameJp: '輸入通関',
    nameEn: 'Import Customs Clearance',
    iconName: 'im_customs',
    entityId: 'IM_CUSTOMS',
    pageUrl: '/entity/im-customs-clearance/',
    pageName: 'ListImCustoms',
    approval: true,
    code: 7
  }
};

// プロセス
export const processNames = {
  COPO: {
    parent: 'CONTRACT',
    nameJp: 'PurchaseOrder 送付',
    nameEn: 'Send Purchase Order',
    nameShort: 'Send P/O',
    nameExShort: 'Snd P/O',
    processId: 'COPO',
    processIds: ['COPO'],
    processType: 'PT_COPO',
    approval: true
  },
  COCOS: {
    parent: 'CONTRACT',
    nameJp: '契約締結',
    nameEn: 'Issue Contract',
    nameShort: 'COS/COP',
    nameExShort: 'COS/COP',
    processId: 'COCOS',
    processIds: ['COCOS'],
    processType: 'PT_COCOS',
    approval: true
  },
  LCACCEPT: {
    parent: 'LC',
    nameJp: 'Letter of Credit保管',
    nameEn: 'Store Letter of Credit',
    nameShort: 'Store L/C',
    nameExShort: 'Str L/C',
    processId: 'LCACCEPT',
    processIds: ['LCACCEPT'],
    processType: 'PT_LCACCEPT',
    approval: true
  },
  TPARB: {
    parent: 'TRANSPORTATION_AG',
    nameJp: 'Booking依頼',
    nameEn: 'Request Booking',
    nameShort: 'Request BKG',
    nameExShort: 'Req BKG',
    processId: 'TPARB',
    processIds: ['TPARB'],
    processType: 'PT_TPATPRB',
    approval: true
  },
  ECREQ: {
    parent: 'EX_CUSTOMS',
    nameJp: '輸出船積依頼',
    nameEn: 'Exp. Shipping Instruction',
    nameShort: 'Ex. S/I',
    nameExShort: 'Ex. S/I',
    processId: 'ECREQ',
    processIds: ['ECREQ01', 'ECREQ02', 'ECREQ03', 'ECREQ04', 'ECREQ05'],
    processType: 'PT_ECREQ',
    approval: true
  },
  ECPERMIT: {
    parent: 'EX_CUSTOMS',
    nameJp: '輸出許可書保管',
    nameEn: 'Store Ex. Customs Permit',
    nameShort: 'Store Ex. CP',
    nameExShort: 'Str ECP',
    processId: 'ECPERMIT',
    processIds: ['ECPERMIT'],
    processType: 'PT_ECPERMIT',
    approval: true
  },
  TPRB: {
    parent: 'TRANSPORTATION',
    nameJp: 'Booking依頼',
    nameEn: 'Request Booking',
    nameShort: 'Request BKG',
    nameExShort: 'Req BKG',
    processId: 'TPRB',
    processIds: ['TPRB'],
    processType: 'PT_TPATPRB',
    approval: true
  },
  TPRBL: {
    parent: 'TRANSPORTATION',
    nameJp: 'B/L発行依頼',
    nameEn: 'Request Bill of Lading',
    nameShort: 'Request B/L',
    nameExShort: 'Req B/L',
    processId: 'TPRBL',
    processIds: ['TPRBL'],
    processType: 'PT_TPRBL',
    approval: true
  },
  TPBL: {
    parent: 'TRANSPORTATION',
    nameJp: 'B/L保管',
    nameEn: 'Store Bill of Lading',
    nameShort: 'Store B/L',
    nameExShort: 'Str B/L',
    processId: 'TPBL',
    processIds: ['TPBL'],
    processType: 'PT_TPBL',
    approval: true
  },
  TPBLI: {
    parent: 'TRANSPORTATION',
    nameJp: 'B/L情報',
    nameEn: 'Bill of Lading Information',
    nameShort: 'B/L Info',
    nameExShort: 'B/L Inf',
    processId: 'TPBLI',
    processIds: ['TPBLI01', 'TPBLI02'],
    processType: 'PT_TPBLI',
    approval: true
  },
  // TPAWB: {
  //   parent: 'TRANSPORTATION',
  //   nameJp: 'AWB保管',
  //   nameEn: 'Store Air Waybill',
  //   nameShort: 'Store AWB',
  //   nameExShort: 'Str AWB',
  //   processId: 'TPAWB',
  //   processType: 'PT_TPAWB',
  //   approval: false
  // },
  MIRIP: {
    parent: 'MARINE_IP',
    nameJp: 'I/P発行依頼',
    nameEn: 'Request Insurance Policy',
    nameShort: 'Request I/P',
    nameExShort: 'Req I/P',
    processId: 'MIRIP',
    processIds: ['MIRIP01', 'MIRIP02', 'MIRIP03'],
    processType: 'PT_MIRIP',
    approval: true
  },
  MIIP: {
    parent: 'MARINE_IP',
    nameJp: 'I/P保管',
    nameEn: 'Store Insurance Policy',
    nameShort: 'Store I/P',
    nameExShort: 'Str I/P',
    processId: 'MIIP',
    processIds: ['MIIP'],
    processType: 'PT_MIIP',
    approval: true
  },
  FDCRTDOC: {
    parent: 'FINAL_DOCS',
    nameJp: '船積書類作成',
    nameEn: 'Create Shipping Documents',
    nameShort: 'Create S.Docs',
    nameExShort: 'Crt DOC',
    processId: 'FDCRTDOC',
    processIds: ['FDCRTDOC'],
    processType: 'PT_FDCRTDOC',
    approval: false
  },
  FDCOR: {
    parent: 'FINAL_DOCS',
    nameJp: 'C/O申請',
    nameEn: 'Request Certificate of Origin',
    nameShort: 'Request C/O',
    nameExShort: 'Req C/O',
    processId: 'FDCOR',
    processIds: ['FDCOR01', 'FDCOR02', 'FDCOR03', 'FDCOR04', 'FDCOR05'],
    processType: 'PT_FDCOR',
    approval: true
  },
  FDCOI: {
    parent: 'FINAL_DOCS',
    nameJp: 'C/O発行',
    nameEn: 'Issue Certificate of Origin',
    nameShort: 'Issue C/O',
    nameExShort: 'Iss C/O',
    processId: 'FDCOI',
    processIds: ['FDCOI01', 'FDCOI02', 'FDCOI03', 'FDCOI04', 'FDCOI05'],
    processType: 'PT_FDCOI',
    approval: true
  },
  FDDOC: {
    parent: 'FINAL_DOCS',
    nameJp: '船積書類送付',
    nameEn: 'Send Shipping Documents',
    nameShort: 'Send S.Docs',
    nameExShort: 'Snd DOC',
    processId: 'FDDOC',
    processIds: ['FDDOC'],
    processType: 'PT_FDDOC',
    approval: true
  },
  ICREQ: {
    parent: 'IM_CUSTOMS',
    nameJp: '輸入荷捌依頼',
    nameEn: 'Imp. Instruction',
    nameShort: 'Im. Ins',
    nameExShort: 'Im. Ins',
    processId: 'ICREQ',
    processIds: ['ICREQ01', 'ICREQ02', 'ICREQ03', 'ICREQ04', 'ICREQ05'],
    processType: 'PT_ICREQ',
    approval: true
  },
  ICPERMIT: {
    parent: 'IM_CUSTOMS',
    nameJp: '輸入許可書保管',
    nameEn: 'Store Im. Customs Permit',
    nameShort: 'Store Im. CP',
    nameExShort: 'Str ICP',
    processId: 'ICPERMIT',
    processIds: ['ICPERMIT'],
    processType: 'PT_ICPERMIT',
    approval: true
  }
};

export const processIds = [
  'COPO',
  'COCOS',
  'LCACCEPT',
  'TPARB',
  'ECREQ01',
  'ECREQ02',
  'ECREQ03',
  'ECREQ04',
  'ECREQ05',
  'ECPERMIT',
  'TPRB',
  'TPRBL',
  'TPBL',
  'TPBLI01',
  'TPBLI02',
  // 'TPAWB',
  'MIRIP01',
  'MIRIP02',
  'MIRIP03',
  'MIIP',
  'FDCRTDOC',
  'FDCOR01',
  'FDCOI01',
  'FDCOR02',
  'FDCOI02',
  'FDCOR03',
  'FDCOI03',
  'FDCOR04',
  'FDCOI04',
  'FDCOR05',
  'FDCOI05',
  // 'FDCOI01',
  // 'FDCOI02',
  // 'FDCOI03',
  // 'FDCOI04',
  // 'FDCOI05',
  'FDDOC',
  'ICREQ01',
  'ICREQ02',
  'ICREQ03',
  'ICREQ04',
  'ICREQ05',
  'ICPERMIT'
];
