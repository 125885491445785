/* eslint-disable */
const items = [
  {
    id: 1,
    name: "calendar",
    width: 840,
    height: 360,
  },
  {
    id: 2,
    name: "blockchain",
    width: 408,
    height: 360,
  },
  {
    id: 3,
    name: "trading",
    width: 840,
    height: 360,
  },
  {
    id: 4,
    name: "news",
    width: 408,
    height: 360,
  },
  // {
  //   id: 5,
  //   name: "alert",
  //   width: 408,
  //   height: 360,
  // },
  {
    id: 6,
    name: "process",
    width: 840,
    height: 360,
  },
  {
    id: 12,
    name: "external",
    width: 408,
    height: 360,
  },
  // {
  //   id: 8,
  //   name: "ship",
  //   width: 840,
  //   height: 360,
  // },
  // {
  //   id: 7,
  //   name: "air",
  //   width: 408,
  //   height: 360,
  // },
  {
    id: 9,
    name: "confirmation",
    width: 408,
    height: 360,
  },
  {
    id: 10,
    name: "comments",
    width: 408,
    height: 360,
  },
  {
    id: 11,
    name: "mail",
    width: 408,
    height: 360,
  },
  {
    id: 13,
    name: "partner",
    width: 408,
    height: 360,
  },
];

export default items;
