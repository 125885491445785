import { COMPANY_ROLE_CD_VARIABLES_OPTIONS, USER_ROLE_CD, USER_ROLE_CD_VARIABLES_OPTIONS } from 'lib-tw-common';

/**
 * ユーザーロールのラベルの配列を返す
 *
 * @param {object} userInformationV2 - User information object.
 * @returns {Array} Array of user roles.
 */
export const getArrayOfUserRoleLabel = (userInformationV2) => {
  const userRoles = []

  if(userInformationV2?.viewerFlg) userRoles.push(getUserRoleLabel(USER_ROLE_CD.VIEWER))
  if(userInformationV2?.picFlg) userRoles.push(getUserRoleLabel(USER_ROLE_CD.PIC))
  if(userInformationV2?.approverFlg) userRoles.push(getUserRoleLabel(USER_ROLE_CD.APPROVER))
  if(userInformationV2?.companyAdminFlg) userRoles.push(getUserRoleLabel(USER_ROLE_CD.COMPANY_ADMIN))
  if(userInformationV2?.twAdminFlg) userRoles.push(getUserRoleLabel(USER_ROLE_CD.TW_ADMIN))

  return userRoles
}

const getUserRoleLabel = (userRoleCdId) => {
  return USER_ROLE_CD_VARIABLES_OPTIONS.find(option => option.id === userRoleCdId)?.label || 'Invalid User Role'
}

/**
 * カンパニーロールのラベルの配列を返す
 *
 * @param {Object} userInformationV2 - The user information object.
 * @returns {Array} - An array of company roles.
 */
export const getArrayOfCompanyRoleLabel = (userInformationV2) => {
  return userInformationV2?.companyRoles?.map(companyRole => {
    return COMPANY_ROLE_CD_VARIABLES_OPTIONS.find(option => option.id === companyRole)?.label || 'Invalid Company Role'
  }) || []
}
