import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import _ from 'lodash';
import autosize from 'v-autosize/dist/plugin';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import sanitizeHTML from 'sanitize-html';
import VueClipboard from 'vue-clipboard2';
import VueChatScroll from 'vue-chat-scroll';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import DefaultSrc from '@/utils/defaultSrc';
import VueGtm from '@gtm-support/vue2-gtm';
import VueI18n from 'vue-i18n';
import { formatResponseDate, formatResponseDateTime } from '@/utils/searchUtil.js';
import VueObserveVisibility from 'vue-observe-visibility';
import { LANGUAGE_CODE } from 'lib-tw-common';

// 言語ファイル
import message_ENGLISH from '../public/messages/message_ENGLISH.json';
import message_JAPANESE from '../public/messages/message_JAPANESE.json';

import '@/assets/styles/element-variables.scss';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'swiper/css/swiper.css'
import router from './router';
import store from './store';
import App from './App.vue';

Vue.prototype.$_ = _;
Vue.prototype.$sanitize = sanitizeHTML;

Vue.use(VueCompositionAPI);
Vue.use(autosize);
Vue.use(ElementUI, { locale });
Vue.use(VueClipboard);
Vue.use(VueChatScroll);
Vue.use(PerfectScrollbar);
Vue.use(VueAwesomeSwiper);
Vue.use(DefaultSrc);
Vue.use(VueI18n);
Vue.use(VueObserveVisibility);

Vue.use(
  VueGtm,
  {
    id: "GTM-Z9BVTPMW",
    vueRouter: router
  },
);

dayjs.locale('en');
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);


// 人間が読みやすいファイルサイズ表記にフォーマットします
Object.defineProperty(Number.prototype,'fileSize',{value:function(a,b,c,d){
return (a=a?[1e3,'k','B']:[1024,'K','iB'],b=Math,c=b.log,
d=c(this)/c(a[0])|0,this/b.pow(a[0],d)).toFixed(1)
+''+(d?(a[1]+'MGTPEZY')[--d]+a[2]:'Bytes');
},writable:false,enumerable:false});

Vue.filter('fileSize', function (value) {
  if (!value) {
    value = 0;
  }
  return value.fileSize(1);
});

Vue.filter('dayFormat', function (value) {
  if (!value) return '';
  return dayjs(value).utc().format('D');
});

Vue.filter('dayLocalFormat', function (value) {
  if (!value) return '';
  return dayjs(value).format('D');
});

Vue.filter('dayOfWeekFormat', function (value) {
  if (!value) return '';
  return dayjs(value).format('ddd');
});

Vue.filter('utcOffsetDateFormat', function (value) {
  if (!value) return '';
  return dayjs(formatResponseDate(value)).format('YYYY-MM-DD');
});

Vue.filter('utcOffsetDateTimeFormat', function (value) {
  if (!value) return '';
  return dayjs(formatResponseDateTime(value)).format('YYYY-MM-DD HH:mm');
});

Vue.filter('addTimeFormat', function (value) {
  if (!value) return '';
  return value + 'T00:00:00Z';
});

Vue.filter('dateFormat', function (value) {
  if (!value) return '';
  return dayjs(value).utc().local().format('YYYY-MM-DD');
});

Vue.filter('dateTimeFormat', function (value) {
  if (!value) return '';
  return dayjs(value).utc().local().format('YYYY-MM-DD HH:mm');
});

Vue.filter('dateFormatShort', function (value) {
  if (!value) return '';
  return dayjs(value).utc().local().format('MMM.DD');
});

Vue.filter('dateFormatMonth', function (value) {
  if (!value) return '';
  return dayjs(value).utc().local().format('YYYY MMM');
});

Vue.filter('timeFormatShort', function (value) {
  if (!value) return '';
  return value.slice(0, -3);
});

// YYYYMMDD、YYMMDDの数値型日付
Vue.filter('dateNumber', function (value) {
  if (!value) return '';
  value = String(value);
  if (value.length === 6) {
    // YYMMDDの時
    value = `20${value}`;
  }
  return dayjs(value).format('YYYY-MM-DD');
});

// YYMMDDHHmmの数値型日付時刻
Vue.filter('dateTimeNumber', function (value) {
  if (!value) return '';
  value = `20${value}`;
  return dayjs(value).format('YYYY-MM-DD HH:mm');
});

// ファイルパスからファイル名を取得します
Vue.filter('fileName', function (path) {
  if (!path) return '';
  // eslint-disable-next-line no-useless-escape
  const filename = _.get(path.match(".+/(.+?)([\?#;].*)?$"), [1]);
  return filename ? filename : path;
});

Vue.filter('newlines', function (text) {
  return sanitizeHTML(text ? text.replace(/\n/g, '<br />') : '');
});

Vue.filter('startCase', function (text) {
  return sanitizeHTML(text ? _.startCase(text) : '');
});

Vue.config.productionTip = false;

// 言語
const language = store.state.locale;
// CSS多言語化対応 言語設定に応じて[lang]属性を変更 言語設定CSSファイル:language.scss
const lang = _.get(LANGUAGE_CODE, `${language}`, 'ja');
document.getElementsByTagName('html')[0].lang = lang;

// console.log(language)
// 言語辞書
const messages = Object.assign({}, message_ENGLISH, message_JAPANESE);

// 言語の設定
export const i18n = new VueI18n({
  locale: language,
  messages: messages
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
