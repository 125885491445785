import _ from 'lodash';
import { LANGUAGE_SETTING, LOCAL_LANGUAGE_VARIABLES, LANGUAGE_CODE } from 'lib-tw-common';

export default {
  computed: {
    localLanguage() {
      return this.$store.state.localLanguage;
    },
    languages() {
      return [
        {label: `Local: ${_.capitalize(_.get(_.find(LOCAL_LANGUAGE_VARIABLES, {code: this.localLanguage}), 'label'))}`, locale: _.get(_.find(LOCAL_LANGUAGE_VARIABLES, {code: this.localLanguage}), 'label'), code: LANGUAGE_SETTING.LOCAL},
        {label: 'English', locale: 'ENGLISH', code: LANGUAGE_SETTING.ENGLISH},
      ];
    },
  },
  methods: {
    setLanguage(language) {
      const locale = _.get(_.find(this.languages, {code: language}), 'locale');
      this.$store.commit('SET_LOCALE', {language, locale});
      this.$i18n.locale = locale;

      // CSS多言語化対応 言語設定に応じて[lang]属性を変更 言語設定CSSファイル:language.scss
      const lang = _.get(LANGUAGE_CODE, `${locale}`, 'ja');
      document.getElementsByTagName('html')[0].lang = lang;
    },
  }
};
