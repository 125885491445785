/**
 * デフォルト画像ディレクティブ
 */

exports.install = function(Vue) {
  Vue.directive('DefaultSrc', {
    bind: (el, binding) => {
      const src = binding.value === 'user' ? '/images/avatar.png' : '/images/customer.png';
      if (!el.getAttribute('src')) {
        el.src = src;
      }
      this.setSrc = () => {
        el.src = src;
      };
      

      el.addEventListener('error', this.setSrc, false);
    },
    unbind: (el) => {
      el.removeEventListener('error', this.setSrc, false);
    }
  });
};
