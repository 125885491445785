<template>
<div class="backdrop" v-if="isProcessing" @click.stop.prevent @touchmove.stop.prevent>
  <!-- <div class="sk-cube-grid">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div> -->

  <img src="@/assets/images/ship.gif" class="ship">
  <div class="progress">
    <div class="progress-bar-bg">
      <!-- <div class="progress-bar" :style="{ clipPath: `inset(0 ${100 - width}% -1px -1px round 100px)` }"></div> -->
      <div class="progress-bar" :style="{ width: width + '%' }"></div>
    </div>
    <div class="percent">{{ parseInt(width) }}%</div>
  </div>
  <div class="progress_message">{{$t('Message.Processing')}}</div>
</div>
</template>

<script>
export default {
  computed: {
    isProcessing() {
      return this.$store.state.progressBar.processing;
    },
    width() {
      return this.$store.state.progressBar.progress;
    }
  }
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color_primary_blue, .75);
  z-index: 10000;
}

.ship {
  display: block;
  width: 142px;

}

.progress {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  margin-bottom: 48px;

  .percent {
    width: 42px;
    margin-left: 8px;
    color: $color_white;
    font-size: 16px;
    font-weight: 700;
  }
}

.progress-bar-bg {
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  width: 240px;
  height: 18px;
  background: $color_gray_300;
  border-radius: 100px;

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    // width: 240px;
    width: 0;
    height: 18px;
    background: linear-gradient(90deg, #5185C5 0%, #3EC1FF 100%);
    transition: width .1s linear 0s;
    border-radius: 100px;
  }
}

.progress_message {
  color: $color_white;
  font-size: 24px;
  font-weight: 700;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 30px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #409eff;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
</style>
