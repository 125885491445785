import _ from 'lodash';
import dayjs from 'dayjs';
import store from '@/store';
import { PROCESS_PROGRESS_STATUS, INITIAL_VIEW_FLG } from 'lib-tw-common';
import { entityNames } from '@/dictionaries/map.js';

const kanaDict = {
  ァ: 'ア',
  ィ: 'イ',
  ゥ: 'ウ',
  ェ: 'エ',
  ォ: 'オ',
  ヵ: 'カ',
  ヶ: 'ケ',
  ッ: 'ツ',
  ャ: 'ヤ',
  ュ: 'ユ',
  ョ: 'ヨ',
  ヮ: 'ワ'
};

/**
 * ひらがなをカタカナに変換します
 * @param { String} str
 */
export const hiraToKana = str => {
  return str.replace(/[\u3041-\u3096]/g, match => {
    var chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
};

/**
 * 全角英数字を半角英数字に変換します
 * @param { String } str
 */
export const zenkakuToHankaku = str => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

/**
 * カタカナの小さい文字を大きい文字に変換します
 * @param { String } str
 */
export const kanaToUpperCase = str => {
  return _.map(str, s => {
      if (kanaDict[s]) {
        return kanaDict[s];
      }
      return s;
    })
    .join('');
};

/**
 * 検索文字列をノーマライズします
 * @param { String } str
 */
export const normalize = str => {
  return kanaToUpperCase(hiraToKana(zenkakuToHankaku(str))).toLowerCase();
};

/**
 * セクション選択肢をソートして返却します（2021/9/24使用しなくなった）
 * @param { Array } companies
 */
export const sortSection = companies => {
  return _.orderBy(_.map(companies, o => {
    return {
      tradingId: o.tradingId,
      tradingName: o.tradingName,
      tradingNameEn: o.tradingNameEn,
      tradingNameJp: o.tradingNameJp,
      sectionList: _.orderBy(o.sectionList, ['sectionShortName', 'sectionId'], ['asc', 'asc'])
    };
  }), ['tradingName', 'tradingId'], ['asc', 'asc'])
};

/**
 * セクション選択肢をプルダウン用に変換します
 * @param { Array } companies
 */
export const sectionForPulldown = companies => {
  const lang = store.getters.getLanguageKey;
  return _.uniqBy(_.reduce(companies, (res, company) => {
    _.forEach(company.sectionList, o => {
      res.push({
        value: o.sectionId,
        label: `${company[lang].tradingName}/${o[lang].sectionName}`,
        tradingId: company.tradingId,
        tradingName: company[lang].tradingName,
        tradingShortName: company[lang].tradingShortName,
        sectionId: o.sectionId,
        sectionName: o[lang].sectionName,
        sectionShortName: o[lang].sectionShortName,
        en: `${company.en.tradingName}/${o.en.sectionName}`,
        local: `${company.local.tradingName}/${o.local.sectionName}`,
        tradingIcon: company.tradingIcon,
      });
    })
    return res;
  }, []), 'value');
};

/**
 * セクション選択肢をカスケーダー用に変換します
 * @param { Array } companies
 */
export const sectionForCascader = companies => {
  const lang = store.getters.getLanguageKey;
  return _.orderBy(_.map(companies, o => {
    return {
      value: o.tradingId,
      label: o[lang].tradingName,
      keywords: _(o).keys().filter(key => key.includes('keyword')).map(key => o[key]).value(),
      children: _.orderBy(_.map(o.sectionList, s => {
        return {
          value: s.sectionId,
          label: s[lang].sectionName,
          keywords: _(s).keys().filter(key => key.includes('keyword')).map(key => o[key]).value(),
        }
      }), ['label', 'value'], ['asc', 'asc'])
    };
  }), ['label', 'value'], ['asc', 'asc'])
};

// Dateを時間を切り捨て、送信可能な形に整形します
export const formatRequestDate = date => {
  return date ? dayjs(date).format('YYYY-MM-DD') : null;
}

// DateTimeを送信可能な形に整形します
export const formatRequestDateTime = date => {
  return date ? date + ':00' : null;
  // return date ? (dayjs(date).local().format('YYYY-MM-DDTHH:mm:ss') + 'Z') : null;
}

// レスポンスの絶対日付をDate型に変換します
export const formatResponseDate = date => {
  return date ? dayjs(date.slice(0, 10)).toDate() : null;
}

// レスポンスの絶対日時をDate型に変換します
export const formatResponseDateTime = date => {
  return date ? dayjs(date.replace('T', ' ').slice(0, 16)).toDate() : null;
}

// Dateを送信可能な形に整形します
export const formatUtcDate = date => {
  return date ? new Date(date).toISOString().slice(0, -5) + 'Z' : null;
}

// EndDateを送信可能な形に整形します
export const formatEndDate = date => {
  return date ? dayjs(date).endOf('date').toDate().toISOString().slice(0, -5) + 'Z' : null;
}

/**
 * デートピッカーのstartDateを返却します（システム日時）
 * @param {Array} dateArray
 */
export const getStartDate = dateArray => {
  const date = _.get(dateArray, '0');
  return formatUtcDate(date);
};

/**
 * デートピッカーのendDateを返却します（システム日時）
 * @param {Array} dateArray
 */
export const getEndDate = dateArray => {
  const date = _.get(dateArray, '1');
  return formatEndDate(date);
};

/**
 * デートピッカーのstartDateを返却します（YYYY-MM-DD）
 * @param {Array} dateArray
 */
export const getYMDStartDate = dateArray => {
  const date = _.get(dateArray, '0');
  return date ? dayjs(date).format('YYYY-MM-DD') : null;
};

/**
 * デートピッカーのendDateを返却します（YYYY-MM-DD）
 * @param {Array} dateArray
 */
export const getYMDEndDate = dateArray => {
  const date = _.get(dateArray, '1');
  return date ? dayjs(date).format('YYYY-MM-DD') : null;
};

/**
 * デートピッカーのstartDateを返却します（YYYY-MM-DD 00:00:00）
 * @param {Array} dateArray
 */
export const getYMDStartDateTime = dateArray => {
  const date = _.get(dateArray, '0');
  return date ? dayjs(date).format('YYYY-MM-DD') + ' 00:00:00' : null;
};

/**
 * デートピッカーのendDateを返却します（YYYY-MM-DD 23:59:59）
 * @param {Array} dateArray
 */
export const getYMDEndDateTime = dateArray => {
  const date = _.get(dateArray, '1');
  return date ? dayjs(date).format('YYYY-MM-DD') + ' 23:59:59' : null;
};

/**
 * デートピッカーのyearMonthを返却します（YYYY-MM）
 * @param {String} date
 */
export const getYearMonth = date => {
  return date ? dayjs(date).format('YYYY-MM') : null;
};

/**
 * プロセス進捗ステータスの送信データを生成します
 * @param {Array} dataArray
 */
export const getProcessProgressStatus = dataArray => {
  return _.reduce(dataArray, (res, n) => {
    res.push(n);
    if (n === PROCESS_PROGRESS_STATUS.T_AWAITING_CONFIRMATION) {
      res.push(PROCESS_PROGRESS_STATUS.T_INPUT_IN_PROGRESS);
    } else if (n === PROCESS_PROGRESS_STATUS.DONE) {
      res.push(PROCESS_PROGRESS_STATUS.AGREED);
    }
    return res;
  }, []);
};

/**
 * tradingFlowIdをtradingIdとFlowIdに分けて返却します
 * @param {String} tradingFlowId
 */
export const getTradingFlowId = tradingFlowId => {
  if (_.isString(tradingFlowId)) {
    const ary = tradingFlowId.split('/');
    if (!ary[0] && ary[1]) {
      return [ary[1]];
    } else {
      return ary;
    }
  }
  return [];
};

// HTMLタグを除去した文字列を返却します（改行コード入り）
export const stripTag = (str) => {
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(str, 'text/html');
  return doc.body.innerText;
};

// 数値をカンマ区切りにして返却します
export const formatNumber = (value, rules = []) => {
  // バリデーションルールから最大小数桁数を取得
  const maximumFractionDigits = getMaximumFractionDigits(rules);
  // 最大小数桁数が7桁の場合は7桁に変更。それ以外は4桁
  const digit = maximumFractionDigits === 7 ? maximumFractionDigits : 4;
  if (!value && value !== 0) return '';
  return Number(String(value)).toLocaleString('en-US', { maximumFractionDigits: digit });
};

// 入力数値をカンマ区切りにして返却します
export const formatInputNumber = value => {
  return formatNumberString(value);
};

/**
 * 数量をフォーマットして返却します。小数点以下は指定桁で四捨五入（デフォルト4桁）
 * @param {Number} value
 * @param {String} unit
 * @param {Number} digit
 * @returns フォーマットした数量
 */
export const formatQuantity = (value, unit = '', digit = 4) => {
  if (!value && value !== 0) return '';
  return Number(String(value)).toLocaleString('en-US', { maximumFractionDigits: digit}) + ' ' + (unit || '');
};

/**
 * // 金額をフォーマットして返却します。小数点以下は通貨の指定桁で切り捨て0埋め（デフォルト2桁）
 * @param {Number} value
 * @param {String} unit
 * @param {Number} digit
 * @returns フォーマットした金額
 */
export const formatCurrency = (value, unit = '', digit = 2) => {
  if (!value && value !== 0) return '';
  return Number(String(value)).toLocaleString('en-US', { minimumFractionDigits: Math.min(digit, 2), maximumFractionDigits: 2}).replace(/\.$/, '') + ' ' + (unit || '');
};

// 数値文字列をカンマ区切りにして返却します（小数点以下はカンマ区切りしない）
export const formatNumberString = value => {
  if (!value && value !== 0) return '';
  const split = String(value).split('.');
  let ret = split[0].replace(/(\d+)(\.\d+)?/, function (subString, capture1, capture2) {
    capture1 = capture1.split(/(?=(?:\d{3})+$)/).join();
    return capture2 ? capture1 + capture2.replace(/(\d{3})(?=\d)/, '$1,') : capture1;
  });
  if (split[1]) ret = `${ret}.${split[1]}`
  return ret
};

/**
 * // 商品価格のPrice表示用。金額をフォーマットして返却します。小数点以下は通貨の指定桁で切り捨て0埋め（デフォルト2桁）
 * @param {Number} value
 * @param {String} unit
 * @param {Number} digit
 * @returns フォーマットした金額
 */
 export const formatPrice = (value, unit = '', digit = 2, unit2) => {
  if (!value && value !== 0) return '';
  return formatCurrency(value, unit, digit) + '/' + unit2;
};

/**
 * UUIDを生成します
 * @returns UUID
 */
export const generateUuid = (length) => {
  // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
  // const FORMAT: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  var chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
  if (length) {
    chars = _.takeRight(chars, length);
  }
  for (var i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case 'x':
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case 'y':
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join('');
};

// 数値を文字列に変換します
export const numberToString = (n) => {
  if (!n && n !== 0) {
    return null
  }
  return n.toString();
};

// エンティティを正しい順序に並び替えます
export const orderEntities = (entityStatus) => {
  const order = _.map(entityNames, o => o.code);
  return _.cloneDeep(_.orderBy(entityStatus, entity => {
    const index = _.findIndex(order, code => {
      return code === entity.entityTypeCd;
    });
    return index;
  }, ['asc']));
};

// 検索の初期化フラグを返却します
// eslint-disable-next-line no-unused-vars
export const initialFlag = queries => {
  // 当分の間初期検索条件はなし
  return INITIAL_VIEW_FLG.OFF;

  // return _.every(queries, value => {
  //   return !_.isNumber(value) && _.isEmpty(value);
  // }) ? INITIAL_VIEW_FLG.ON : INITIAL_VIEW_FLG.OFF;
};

// バリデーションルールから最大文字数を取得し返却します
export const getMaxLength = rules => {
  if (!rules) {
    return false;
  }
  if (_.isString(rules)) {
    rules = [rules];
  }
  const rule = rules.find(rule => rule.includes('tw-isMaxLength'));
  if (rule) {
    return parseInt(rule.replace('tw-isMaxLength', ''));
  }
  return false;
};

// バリデーションルールから最大小数桁数を取得し返却します
export const getMaximumFractionDigits = rules => {
  if (!rules) {
    return false;
  }
  if (_.isString(rules)) {
    rules = [rules];
  }
  const rule = rules.find(rule => rule.includes('tw-isDecimalDigit'));
  if (rule) {
    return parseInt(rule.replace('tw-isDecimalDigit', ''));
  }
  return false;
};

// 配列の任意の項目を任意の位置に移動します
export const  moveAt = (array, index, at) => {
  if (index === at || index > array.length -1 || at > array.length - 1) {
    return array;
  }

  const value = array[index];
  const tail = array.slice(index + 1);

  array.splice(index);

  Array.prototype.push.apply(array, tail);

  array.splice(at, 0, value);

  return array;
};

/**
 * 検索条件をリセットして返却します
 * @return {Object}
 */
// リセットしない項目
const ignoreTarget = ['modeOfTransport', 'paymentTerms1'];
// undefinedで返却する項目
const undefinedTarget = ['totalQuantity', 'invoiceAmountFrom', 'invoiceAmountTo', 'amountMin', 'amountMax'];
// リセット処理
export const clearSearchForm = form => {
  return _.mapValues(form, (val, key) => {
    // console.log(val, key, ignoreTarget.includes(key))
    if (ignoreTarget.includes(key)) return val;
    else if (undefinedTarget.includes(key)) val = undefined;
    else if (_.isString(val)) val = '';
    else if (_.isNumber(val)) val = undefined;
    else if (_.isArray(val)) val = [];
    else if (_.isBoolean(val)) val = false;
    else val = null;
    // console.log(val, key)
    return val;
  });
}

// Date Pickerの入力値フォーマット
export const onDateChange = e => {
  const date = dayjs(e.target.value);
  if (date.isValid()) {
    e.target.value = date.format('YYYY-MM-DD');
    e.preventDefault();
    e.target.parentElement.__vue__.handleInput(e);
    e.target.parentElement.__vue__.handleChange(e);
  }
};

// Date Time Pickerの入力値フォーマット
export const onDateTimeChange = e => {
  const date = dayjs(e.target.value);
  if (date.isValid()) {
    e.target.value = date.format('YYYY-MM-DD HH:mm');
    e.preventDefault();
    e.target.parentElement.__vue__.handleInput(e);
    e.target.parentElement.__vue__.handleChange(e);
  }
};

// Date Range Pickerの入力値フォーマット
export const onDateRangeChange = e => {
  const date = dayjs(e.target.value);
  if (date.isValid()) {
    e.target.value = date.format('YYYY-MM-DD');
    e.preventDefault();
    if (e.target.placeholder === 'Start date') {
      e.target.parentElement.__vue__.handleStartInput(e);
      e.target.parentElement.__vue__.handleStartChange(e);
    } else {
      e.target.parentElement.__vue__.handleEndInput(e);
      e.target.parentElement.__vue__.handleEndChange(e);
    }
  }
};

// Date Time Range Pickerの入力値フォーマット
export const onDateTimeRangeChange = e => {
  const date = dayjs(e.target.value);
  if (date.isValid()) {
    e.target.value = date.format('YYYY-MM-DD HH:mm');
    e.preventDefault();
    if (e.target.placeholder === 'Start date') {
      e.target.parentElement.__vue__.handleStartInput(e);
      e.target.parentElement.__vue__.handleStartChange(e);
    } else {
      e.target.parentElement.__vue__.handleEndInput(e);
      e.target.parentElement.__vue__.handleEndChange(e);
    }
  }
};

// Time Pickerの入力値フォーマット
export const onTimeChange = e => {
  if (/^\d{4}$/.test(e.target.value)) {
    e.target.value = `${e.target.value.slice(0, 2)}:${e.target.value.slice(2, 4)}`;
    e.preventDefault();
    e.target.parentElement.__vue__.handleInput(e);
    e.target.parentElement.__vue__.handleChange(e);
  }
};

// 新しい検索フィールド用に、lslConfig内の特定のパラメータをqueryパラメーターまたはpathパラメーターのうち、存在する方から値を取得
export const getLslConfigParameter = (lslConfig, parameter) => {
  if (typeof lslConfig === 'undefined') {
    return undefined
  }
  if (typeof lslConfig.query !== 'undefined' && typeof lslConfig.query[parameter] !== 'undefined') {
    return lslConfig.query[parameter]
  }
  if (typeof lslConfig.path !== 'undefined' && typeof lslConfig.path[parameter] !== 'undefined') {
    return lslConfig.path[parameter]
  }
  return undefined
};

// スクロールバーの幅を返却します
export const getScrollbarWidth = () => {
  let element = document.createElement('div');
  element.style.visibility = 'hidden';
  element.style.overflow = 'scroll';
  document.body.appendChild(element);
  const scrollbarWidth = element.offsetWidth - element.clientWidth;
  document.body.removeChild(element);

  return scrollbarWidth;
};
