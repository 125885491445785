<template>
  <div id="app">
    <el-container>
      <tw-aside v-if="needMenu" />
      <el-main>
        <router-view v-if="isRouterShow" />
      </el-main>
    </el-container>
    <progress-bar />
  </div>
</template>

<script>
import TwAside from '@/components/organisms/TwAside';
import ProgressBar from '@/components/atoms/ProgressBar.vue';
import mixinLanguage from '@/utils/mixinLanguage.js';
import inactivityTimeout from '@/utils/inactivityTimeout.js';
import { getScrollbarWidth } from '@/utils/searchUtil';

// サイドメニューを出さないページ
const NOMENU_PAGES = [
  'Page404'
];

export default {
  name: 'App',
  mixins: [mixinLanguage, inactivityTimeout],
  components: {
    TwAside,
    ProgressBar,
  },
  data() {
    return {
      isRouterShow: true
    };
  },
  provide() {
    return {
      reload: this.reload 
    }
  },
  computed: {
    companyId() {
      return this.$store.state.companyId;
    },
    needMenu() {
      if (!this.$route.name) {
        return false;
      }
      return !NOMENU_PAGES.includes(this.$route.name);
    },
  },
  watch: {
    companyId() {
      this.fetch();
    }
  },
  created() {
    this.resetInactivityInterval();
    if (this.companyId) {
      this.fetch();
    }
    // this.$store.dispatch('GET_UNREAD_COUNT');
  },
  mounted() {
    this.$store.commit('SET_SCROLLBAR_WIDTH', getScrollbarWidth());
  },
  methods: {
    fetch() {
      this.$store.dispatch('GET_SYSTEM_PROPERTIES');
      this.$store.dispatch('GET_USER_PROFILE')
      .then(this.setLanguage)
      .catch(() => {});
    },
    async reload() {
      this.isRouterShow = false
      await this.$nextTick()
      this.isRouterShow = true
    }
  }
};
</script>
