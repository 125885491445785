import { $api } from '@/store/ApiClient';

const inactivityInterval = 60 * 60 * 1000; // 60分
const inactivityTimeoutDetectionInterval = 10 * 1000; // 10秒
const ignoreListenerDetectionTimeout = 1 * 1000; // 1秒

export default {
  data() {
    return {
      inactivityIntervalID: null,
      ignoreListenerDetectionTimeoutID: null,
      canResetTimer: true// true の間のみ setTimeout によるタイマーの更新を可能とする
    };
  },
  methods: {
    resetInactivityInterval() {
      clearInterval(this.inactivityIntervalID);
      const targetTime = Date.now() + inactivityInterval;
      this.inactivityIntervalID = setInterval(() => {
        if(Date.now() > targetTime) {
          this.logout()
          // NOTE: サーバー側セッションタイムアウトが先に発生した場合フロント側のログアウト処理(401エラーとなる)がそれ繰り返されるのを防ぐため、タイマーをクリアする
          clearInterval(this.inactivityIntervalID);
        }
      }, inactivityTimeoutDetectionInterval);
    },
    resetIgnoreListenerDetectionTimeout() {
      clearTimeout(this.ignoreListenerDetectionTimeoutID);
      this.ignoreListenerDetectionTimeoutID = setTimeout(this.deactivate, ignoreListenerDetectionTimeout);
    },
    logout() {
      $api.signOut();
    },
    activate() {
      if(!this.canResetTimer) return;
      this.canResetTimer = false;
      this.resetIgnoreListenerDetectionTimeout();
      this.resetInactivityInterval();
    },
    deactivate() {
      this.canResetTimer = true;
    }
  },
  created() {
    this.canResetTimer = true;
    window.addEventListener('mousemove', this.activate);
    window.addEventListener('keydown', this.activate);
    window.addEventListener('click', this.activate);
    window.addEventListener('wheel', this.activate);
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.activate);
    window.removeEventListener('keydown', this.activate);
    window.removeEventListener('click', this.activate);
    window.removeEventListener('wheel', this.activate);
    clearInterval(this.inactivityIntervalID);
    clearTimeout(this.ignoreListenerDetectionTimeoutID);
  }
}