import sectionNotificationSettings from '@/../public/dummyData/sectionNotificationSettings.json';

const _dataMapper = {
  'tw-user-entity-bff-api': {
    'get_/v1/user-settings/notification-settings': sectionNotificationSettings
  }
}

// eslint-disable-next-line import/prefer-default-export
export function dummyRequest(params, dummyParams) {
  if(window.location.hostname !== 'localhost') {
    console.error('local環境以外でdummy APIが使用されています');
  }
  return new Promise(async (resolve, reject) => {
    if(dummyParams.resObj) {
      if(dummyParams.isError) reject('resObjが指定されましたが、isErrorがtrueです。');
      resolve(dummyParams.resObj);
      return;
    }

    if(!_dataMapper?.[params.lslConfig.serviceCode]?.[params.lslConfig.apiCode]) {
      const noFileMessage = 'dummy responseに対応するjsonファイルが存在しません。';
      if(dummyParams.isError) reject(noFileMessage);
      resolve(noFileMessage);
      return;
    }

    if(dummyParams.isError) {
      reject(
        dummyParams.errorObj || 
        {
          errorCode: dummyParams.errorCode || 900,
          errorMessage: dummyParams.errorMessage || 'dummy error'
        }
      );
    }
    resolve(_dataMapper[params.lslConfig.serviceCode][params.lslConfig.apiCode]);
  })
}